import classnames from 'classnames';
import { graphql, Link, useStaticQuery, navigate } from 'gatsby';
import Img from 'gatsby-image';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import SearchInput from './../components/SearchInput';
import Button from './common/Button';
import NavItem from './common/NavItem';
import SubNavItem from './common/SubNavItem';
import './Navbar.css';
import { ShoppingCartIcon } from '@heroicons/react/solid';
import { useLocalStorage } from 'usehooks-ts';
import SignInModal from './SignInModal';
import { EventSectionsCartModel } from './event-sections/EventSectionsForm';
import EventSectionsCart, {
  EVENT_SECTION_CART_DEFAULT
} from './event-sections/EventSectionsCart';

interface MobileMenuSubNavLinkProps {
  label: string;
  link: string;
  children?: React.ReactNode;
}
const MobileMenuSubNavLink: React.FC<MobileMenuSubNavLinkProps> = props => {
  return (
    <Link
      to={props.link}
      className="text-blue-200 hover:bg-primary-dark hover:text-blue-100 block px-3 py-2 rounded-md text-base font-medium ml-4 sans-serif"
    >
      {props.label}
    </Link>
  );
};

interface MobileMenuNavLinkProps {
  label: string;
  link: string;
  externalLink?: string;
  children?: React.ReactNode;
}
const MobileMenuNavLink: React.FC<MobileMenuNavLinkProps> = props => {
  return (
    <>
      {props.externalLink ? (
        <a
          href={props.externalLink ? props.externalLink : '#'}
          className="text-blue-200 hover:bg-primary-dark hover:text-blue-100 block px-3 py-2 rounded-md text-base font-medium sans-serif"
        >
          {props.label}
        </a>
      ) : (
        <Link
          to={props.link}
          className="text-blue-200 hover:bg-primary-dark hover:text-blue-100 block px-3 py-2 rounded-md text-base font-medium sans-serif"
        >
          {props.label}
        </Link>
      )}
      {props.children}
    </>
  );
};

interface MobileMenuProps {
  showMobileMenu: boolean;
  authState: any;
}
const MobileMenu: React.FC<MobileMenuProps> = props => {
  const mobileMenuClassnames = classnames({
    'bg-primary lg:hidden': true,
    hidden: !props.showMobileMenu,
    block: !!props.showMobileMenu
  });
  return (
    <div className={mobileMenuClassnames}>
      <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <MobileMenuNavLink label="About" link="#">
          <MobileMenuSubNavLink label="Our Mission" link="/our-mission" />
          <MobileMenuSubNavLink label="Our People" link="/our-people" />
        </MobileMenuNavLink>
        <MobileMenuNavLink label="Events" link="/events" />
        <MobileMenuNavLink label="Professional Development" link="#">
          <MobileMenuSubNavLink label="Services" link="/services" />
          <MobileMenuSubNavLink
            label="Saturday Reunion"
            link="/events/saturday-reunion-virtual"
          />
          <MobileMenuSubNavLink
            label="Foundational Reading Skills Online Modules"
            link="/foundational-reading-skills-online-modules"
          />
        </MobileMenuNavLink>
        <MobileMenuNavLink label="Resources" link="#">
          <MobileMenuSubNavLink
            label="Supports for Families"
            link="/resources/supports-for-familes"
          />
          <MobileMenuSubNavLink label="Resource Catalog" link="/resources" />
          <MobileMenuSubNavLink
            label="Search Our Resources"
            link="/resource-center"
          />
        </MobileMenuNavLink>
        <MobileMenuNavLink label="Blog" link="/blog" />
        <MobileMenuNavLink label="Search" link="/search" />
        <MobileMenuNavLink
          label={props.authState ? 'Dashboard' : 'Login'}
          link={props.authState ? '/members/profile' : '/auth/login'}
        />
      </div>
    </div>
  );
};

interface BurgerProps {
  setShowMobileMenu: any;
  logo: any;
}
const Burger: React.FC<BurgerProps> = props => {
  return (
    <div className="-mr-2 flex lg:hidden p-4">
      <button
        onClick={props.setShowMobileMenu}
        className="bg-primary inline-flex items-center justify-center p-2 rounded-md text-blue-200 hover:text-blue-100 hover:bg-primary-dark  my-auto mr-4"
      >
        <span className="sr-only">Open main menu</span>
        <svg
          className="block h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
        <svg
          className="hidden h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div>
        <Link to="/">
          <Img
            className="w-32 lg:w-48 my-2"
            fluid={props.logo.childImageSharp.fluid}
            alt="Advancing Literacy, Teachers College, Columbia University"
          />
        </Link>
      </div>
    </div>
  );
};

interface NavItemWrapperProps {
  children: React.ReactChild;
}
const NavItemWrapper = (props: NavItemWrapperProps) => (
  <li className="m-auto mr-6 whitespace-nowrap">{props.children}</li>
);

interface NavbarProps {
  siteTitle: string;
}
const Navbar: React.FC<NavbarProps> = props => {
  const auth = useContext(AuthContext);
  const [cart, setCart] = useLocalStorage<EventSectionsCartModel>(
    'eventSectionsCart',
    EVENT_SECTION_CART_DEFAULT
  );

  useEffect(() => {
    if (cart) {
      if (!cart.updatedAt) {
        setCart({
          items: [],
          display: false,
          updatedAt: new Date().toISOString()
        });
      } else {
        const now = new Date();
        const updatedAt = new Date(cart.updatedAt);
        const diff = now.getTime() - updatedAt.getTime();
        const inactiveHours = diff / 1000 / 60 / 60;
        // reset cart after 24 hours of inactivity
        if (inactiveHours > 24) {
          setCart({
            items: [],
            display: false,
            updatedAt: new Date().toISOString()
          });
        }
      }
    }
  }, [cart]);

  const [showSignInModal, setShowSignInModal] = useState(false);

  const [searchTerm, setSearchTerm] = useState();
  const [dropdownOpen, setDropdownOpen] = useState<string>('');
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const navigateToSignIn = () => {
    navigate(`/auth/login?intended=/members/event-cart/checkout`);
  };

  const goToCheckout = () => {
    setCart({ ...cart, display: false, updatedAt: new Date().toISOString() });
    if (!!auth.authState) {
      navigate(`/members/event-cart/checkout`);
    } else {
      setShowSignInModal(true);
    }
  };

  return (
    <nav
      role="navigation"
      className="border-b border-gray-300 bg-white shadow-lg z-10"
    >
      <EventSectionsCart
        cart={cart}
        setCart={setCart}
        goToCheckout={goToCheckout}
      />

      <SignInModal
        isOpen={showSignInModal}
        onClose={() => setShowSignInModal(false)}
        navigateToSignin={navigateToSignIn}
      />
      <div className="h-1 bg-gradient"></div>
      <div className="hidden lg:flex justify-between py-4 px-8">
        <div className="flex">
          <div className="">
            <Link to="/">
              <Img
                className="w-auto lg:w-64 m-2"
                fluid={data.logo.childImageSharp.fluid}
                alt="Advancing Literacy, Teachers College, Columbia University"
              />
            </Link>
          </div>
          <ul className="m-auto flex ml-12">
            <NavItemWrapper>
              <>
                <NavItem label="About" to="#" />
                <ul>
                  <li>
                    <SubNavItem label="Our Mission" to="/our-mission" />
                  </li>
                  <li>
                    <SubNavItem label="Our People" to="/our-people" />
                  </li>
                </ul>
              </>
            </NavItemWrapper>
            {/* <NavItemWrapper>
              <NavItem label="Get Started" to="/get-started" />
            </NavItemWrapper> */}
            <NavItemWrapper>
              <NavItem label="Events" to="/events" />
            </NavItemWrapper>
            <NavItemWrapper>
              <>
                <NavItem label="Professional Development" to="#" />
                <ul>
                  <li>
                    <SubNavItem label="Services" to="/services" />
                  </li>
                  <li>
                    <SubNavItem
                      label="Saturday Reunion"
                      to="/events/saturday-reunion-virtual"
                    />
                  </li>
                  <li>
                    <SubNavItem
                      label="Foundational Reading Skills Online Modules"
                      to="/foundational-reading-skills-online-modules"
                    />
                  </li>
                </ul>
              </>
            </NavItemWrapper>

            <NavItemWrapper>
              <>
                <NavItem label="Resources" to="#" />
                <ul>
                  <li>
                    <SubNavItem
                      label="Supports for Families"
                      to="/resources/supports-for-familes"
                    />
                  </li>
                  <li>
                    <SubNavItem label="Resource Catalog" to="/resources" />
                  </li>
                  <li>
                    <SubNavItem
                      label="Search Our Resources"
                      to="/resource-center"
                    />
                  </li>
                </ul>
              </>
            </NavItemWrapper>

            <NavItemWrapper>
              <>
                <NavItem label="Blog" to="/blog" />
              </>
            </NavItemWrapper>
          </ul>
        </div>
        <div className="flex">
          <div className="mr-4 m-auto w-64 hidden xl:block">
            <SearchInput
              placeholder="Search"
              withButton={true}
              onChange={(e: any) => setSearchTerm(e.target.value)}
              onClick={() => {
                navigate(
                  searchTerm ? `/search?searchTerm=${searchTerm}` : `/search`
                );
              }}
            />
          </div>
          <div className="xl:hidden m-auto">
            <NavItemWrapper>
              <NavItem label="Search" to="/search" />
            </NavItemWrapper>
          </div>
          <div className="m-auto whitespace-nowrap">
            <Link to={auth.authState ? '/members/profile' : '/auth/login'}>
              <Button text={auth.authState ? 'Dashboard' : 'Log In'} />
            </Link>
          </div>
          {!!cart?.items?.length && (
            <div className="m-auto whitespace-nowrap ml-2">
              <ShoppingCartIcon
                className="cursor-pointer"
                color="#008FEE"
                width={30}
                onClick={() =>
                  cart?.items?.length &&
                  setCart({
                    ...cart,
                    display: true,
                    updatedAt: new Date().toISOString()
                  })
                }
              />
            </div>
          )}
        </div>
      </div>
      <Burger
        setShowMobileMenu={() => setShowMobileMenu(!showMobileMenu)}
        logo={data.logo}
      />
      <MobileMenu showMobileMenu={showMobileMenu} authState={auth.authState} />
    </nav>
  );
};

export default Navbar;
